
import { defineComponent, ref, onMounted, Ref } from 'vue'
import { Toaster } from '@/common/Toaster'
import { DigInputText } from '@digithia/input/text'
import { DigInputController } from '@digithia/input/common'
import { __ } from '@/locales'
import router from '@/router'
import { storeAuth, StoreActionsAuth } from '@/store/auth'

export default defineComponent({
  name: `ViewResetPassword`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const password = ref('')
    const passwordEl: Ref<DigInputText | null> = ref(null)
    const loading = ref(false)

    onMounted(() => {
      if (!passwordEl.value) return
      passwordEl.value.validator = (value: string) => value.length >= 8
      passwordEl.value.errors.validator = __('invalidPassword').toString()
    })

    const resetPassword = async () => {
      loading.value = true
      if (!DigInputController.checkInputsValidity('.reset-password-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: __('invalidForm').toString(),
        })
      } else {
        const res = await storeAuth.dispatch(
          StoreActionsAuth.RESET_PASSWORD,
          password.value,
        )
        loading.value = false
        Toaster.toast(res)
        if (res.success) {
          router.push({ name: 'Projects' })
        }
      }
    }

    return { resetPassword, password, loading, passwordEl }
  },
})
